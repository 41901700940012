.registration-box {
  background-color: #ffffff;
}

.p-fieldset,
.p-fieldset .p-fieldset-legend {
  padding: 0.5em 1em;
}

.p-fieldset-legend {
  width: auto;
  font-size: 14px !important;
  background: #4d505b !important;
  color: #ffffff !important;
}

.p-fieldset-legend a {
  color: #ffffff !important;
}
.p-accordion-header {
  color: #fff;
}

.p-accordion-header-link {
  background-color: #dbebf8 !important;
  color: #fff !important;
}

.p-accordion-header-link:hover {
  background-color: #112a52 !important;
  text-decoration: none;
}
.datatable-filter .p-paginator .p-paginator-current {
  margin-left: auto;
}

.datatable-filter .p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;
}

.datatable-filter .p-progressbar .p-progressbar-value {
  background-color: #607d8b;
}

.datatable-filter .table-header {
  display: flex;
  justify-content: space-between;
}

.datatable-filter .p-datepicker {
  min-width: 25rem;
}

.datatable-filter .p-datepicker td {
  font-weight: 400;
}

.datatable-filter .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem !important;
}

.datatable-filter .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}

.datatable-filter
  .p-datatable.p-datatable-customers
  .p-datatable-thead
  > tr
  > th {
  text-align: left;
}

.datatable-filter
  .p-datatable.p-datatable-customers
  .p-datatable-tbody
  > tr
  > td {
  cursor: auto;
}

.datatable-filter
  .p-datatable.p-datatable-customers
  .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

.datatable-filter
  .p-datatable-customers
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

@media screen and (max-width: 960px) {
  .datatable-filter
    .p-datatable.p-datatable-customers
    .p-datatable-thead
    > tr
    > th,
  .datatable-filter
    .p-datatable.p-datatable-customers
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-filter .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--layer-2);
  }

  .datatable-filter
    .p-datatable.p-datatable-customers
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-filter
    .p-datatable.p-datatable-customers
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }

  .datatable-filter
    .p-datatable.p-datatable-customers
    .p-datatable-tbody
    > tr
    > td
    .p-progressbar {
    margin-top: 0.5rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.logo-back {
  background-color: #112a52;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datatable-filter .p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.5rem 1rem;
  background-color: #4d505b;
  color: #fff;
}

.datatable-filter .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem;
}

.datatable-filter .p-datatable .p-datatable-thead {
  background-color: #4d505b;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: #07bdf4;
  color: #fff;
}

.action-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.featured-action-btn {
  align-items: flex-start;
  justify-content: flex-start;
}

.registration-status.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #ff0038;
}

.registration-status.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background-color: #ff0038;
}

.featured-status.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: green;
}

.featured-status.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background-color: green;
}

.company-info-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.company-info-box {
  width: 318px;
}

.company-info-box h4 {
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
}

.company-info-box p {
  font-size: 1rem;
}

.p-dialog .p-dialog-header {
  background-color: #112a52;
  color: #fff;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #fff;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #07bdf4;
}

/*----- chat page css --------*/
.chat-search-box {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
  padding: 0.75rem 1rem;
}

.chat-search-box .input-group .form-control {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  border-right: 0;
}

.chat-search-box .input-group .form-control:focus {
  border-right: 0;
}

.chat-search-box .input-group .input-group-btn .btn {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  margin: 0;
}

.chat-search-box .input-group .input-group-btn .btn i {
  font-size: 1.2rem;
  line-height: 100%;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .chat-search-box {
    display: none;
  }
}

/************************************************
************************************************
                Users Container
************************************************
************************************************/

.users-container {
  position: relative;
  padding: 1rem 0;
  border-right: 1px solid #e6ecf3;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

/************************************************
************************************************
                    Users
************************************************
************************************************/

.users {
  padding: 0;
}

.users .person {
  position: relative;
  width: 100%;
  padding: 10px 1rem;
  cursor: pointer;
  border-bottom: 1px solid #f0f4f8;
}

.users .person:hover {
  background-color: #4d505b;
  color: #fff;
  /* Fallback Color */
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#e9eff5),
    to(#ffffff)
  );
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(right, #e9eff5, #ffffff);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, #e9eff5, #ffffff);
  /* FF3.6 */
  background-image: -ms-linear-gradient(right, #e9eff5, #ffffff);
  /* IE10 */
  background-image: -o-linear-gradient(right, #e9eff5, #ffffff);
  /* Opera 11.10+ */
  background-image: linear-gradient(right, #e9eff5, #ffffff);
}

.users .person.active-user {
  background-color: #4d505b;
  color: #fff;
  /* Fallback Color */
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f7f9fb),
    to(#ffffff)
  );
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(right, #f7f9fb, #ffffff);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, #f7f9fb, #ffffff);
  /* FF3.6 */
  background-image: -ms-linear-gradient(right, #f7f9fb, #ffffff);
  /* IE10 */
  background-image: -o-linear-gradient(right, #f7f9fb, #ffffff);
  /* Opera 11.10+ */
  background-image: linear-gradient(right, #f7f9fb, #ffffff);
}

.users .person:last-child {
  border-bottom: 0;
}

.users .person .user {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.users .person .user img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.users .person .user .status {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  background: #e6ecf3;
  position: absolute;
  top: 0;
  right: 0;
}

.users .person .user .status.online {
  background: #9ec94a;
}

.users .person .user .status.offline {
  background: #c4d2e2;
}

.users .person .user .status.away {
  background: #f9be52;
}

.users .person .user .status.busy {
  background: #fd7274;
}

.users .person p.name-time {
  font-weight: 600;
  font-size: 0.85rem;
  display: inline-block;
}

.users .person p.name-time .time {
  font-weight: 400;
  font-size: 0.7rem;
  text-align: right;
  color: #8796af;
}

@media (max-width: 767px) {
  .users .person .user img {
    width: 30px;
    height: 30px;
  }
  .users .person p.name-time {
    display: none;
  }
  .users .person p.name-time .time {
    display: none;
  }
}

/************************************************
************************************************
                Chat right side
************************************************
************************************************/

.selected-user {
  width: 100%;
  padding: 0 15px;
  min-height: 64px;
  line-height: 64px;
  border-bottom: 1px solid #e6ecf3;
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

.selected-user span {
  line-height: 100%;
}

.selected-user span.name {
  font-weight: 700;
}

.chat-container {
  position: relative;
  padding: 1rem;
}

.chat-container li.chat-left,
.chat-container li.chat-right {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 40px;
}

.chat-container li img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.chat-container li .chat-avatar {
  margin-right: 20px;
}

.chat-container li.chat-right {
  justify-content: flex-end;
}

.chat-container li.chat-right > .chat-avatar {
  margin-left: 20px;
  margin-right: 0;
}

.chat-container li .chat-name {
  font-size: 0.75rem;
  color: #999999;
  text-align: center;
}

.chat-container li .chat-text {
  padding: 0.4rem 1rem;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #ffffff;
  font-weight: 300;
  line-height: 150%;
  position: relative;
}

.chat-container li .chat-text:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: -20px;
  border: 10px solid;
  border-color: transparent #949aa2 transparent transparent;
}

.chat-container li.chat-right > .chat-text {
  text-align: right;
  background-color: #112a52;
  color: #fff;
}

.chat-container li.chat-left > .chat-text {
  background-color: #949aa2;
  color: #fff;
}

.chat-container li.chat-right > .chat-text:before {
  right: -20px;
  border-color: transparent transparent transparent #112a52;
  left: inherit;
}

.chat-container li .chat-hour {
  padding: 0;
  margin-bottom: 10px;
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 15px;
}

.chat-container li .chat-hour > span {
  font-size: 16px;
  color: #9ec94a;
}

.chat-container li.chat-right > .chat-hour {
  margin: 0 15px 0 0;
}

@media (max-width: 767px) {
  .chat-container li.chat-left,
  .chat-container li.chat-right {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .chat-container li img {
    width: 32px;
    height: 32px;
  }
  .chat-container li.chat-left .chat-avatar {
    margin: 0 0 5px 0;
    display: flex;
    align-items: center;
  }
  .chat-container li.chat-left .chat-hour {
    justify-content: flex-end;
  }
  .chat-container li.chat-left .chat-name {
    margin-left: 5px;
  }
  .chat-container li.chat-right .chat-avatar {
    order: -1;
    margin: 0 0 5px 0;
    align-items: center;
    display: flex;
    justify-content: right;
    flex-direction: row-reverse;
  }
  .chat-container li.chat-right .chat-hour {
    justify-content: flex-start;
    order: 2;
  }
  .chat-container li.chat-right .chat-name {
    margin-right: 5px;
  }
  .chat-container li .chat-text {
    font-size: 0.8rem;
  }
}

.chat-form {
  padding: 15px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.card-chat {
  border: 0;
  background: #ffffff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 2rem;
  box-shadow: none;
}

/* ------ company details page css ----- */
.map-marker {
  height: 40px;
  width: 40px;
  position: relative;
}
.map-popup {
  display: none;
  position: absolute;
  left: -5rem;
  top: 2.5rem;
  max-width: 200px;
  min-width: 200px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.map-marker:hover + .map-popup {
  display: block;
}

.company-title {
  font-size: 1.5rem;
}

.company-details-box h1 {
  border-bottom: none;
}

.p-breadcrumb {
  background: #fff;
}

/*--- company details page css -----*/

.keyword-badges {
  font-size: 1rem;
  font-weight: 400;
}
.word-wrapping {
  white-space: normal;
  width: 350px;
}

.hiring-alert {
  border: 5px solid #112a52;
}

.hiring-alert h1 {
  border-bottom: none;
}

.social-icons {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.social-icons-youtube {
  width: 50px;
  height: 40px;
  margin-right: 10px;
}

.btn-weedc {
  color: #fff;
  background-color: #1c4482;
  border-color: #1c4482;
}

.btn-weedc:hover {
  color: #fff;
  background-color: #112a52;
  border-color: #112a52;
}

/* .company-info-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: column wrap;
  justify-content: space-between;
} */

.company-info-box h4 {
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
}

.assign-badge-modal-content {
  overflow-y: scroll;
}

.assign-badge-btn {
  color: #1c4482;
  padding: 2px 3px;
}

.assign-badge-btn:hover {
  color: #fff;
  background-color: #112a52;
  border-color: #112a52;
}

.assign-badge-btn:active {
  color: #fff;
  background-color: #07bdf4;
  border-color: #07bdf4;
}

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

.p-menu-imp {
  top: 30px !important;
  left: 0px !important;
}

.company-profile-logo-header {
  background: #112a52 !important;
  width: 30px;
  height: 30px;
  border-radius: 27px;
  margin-left: 10px;
}
.header-flag {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.menu-item-logo {
  width: 70px;
  height: 70px;
  object-fit: cover;
  background-color: #333;
  border-radius: 50px;
}
@media (max-width: 576px) {
  .welcome-header {
    display: none;
  }
  .small-menu {
    left: -81px;
  }
  .department-input {
    width: 110px;
  }
}
/* .p-link {
  background-color: transparent !important;
}
.p-link:hover {
  background-color: transparent !important;
} */

.custom-dropdown-btn {
  margin-left: 0px !important;
  color: #000000 !important;
}

.custom-dropdown-btn:hover {
  background-color: #07bdf4 !important;
}

.custom-top-bar-dropdown:active,
.custom-top-bar-dropdown:hover,
.custom-top-bar-dropdown:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
  box-shadow: none !important;
  outline: none !important;
}

.historyslect {
  display: flex;
  width: 223px;
  align-items: center;
  font-weight: 600;
  color: #000;
}

/*------- APMA css --------*/
.apma-forms-tab .nav-link.active {
  background-color: #112a52 !important;
  color: #fff;
}

.apma-forms-tab .nav-link:hover {
  background-color: #23a6d5 !important;
  color: #fff;
}

.apma-input,
:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
}
.contentText {
  text-align: justify;
}
.inlineBlock {
  display: table-cell;
  width: fit-content;
}

.historyslect {
  display: flex;
  width: 223px;
  align-items: center;
  font-weight: 600;
  color: #000;
}

/*RFA CSS*/
table.paleBlueRows {
  border: 1px solid #ffffff;
  width: 900px;
  height: auto;
  text-align: left;
  border-collapse: collapse;
}
table.paleBlueRows td,
table.paleBlueRows th {
  border: 1px solid #ffffff;
  padding: 3px 3px;
}
table.paleBlueRows tbody td {
  font-size: 15px;
}
table.paleBlueRows tr:nth-child(even) {
  background: #d0e4f5;
}
table.paleBlueRows thead {
  background: #0b6fa4;
  border-bottom: 5px solid #ffffff;
}
table.paleBlueRows thead th {
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
  border-left: 2px solid #ffffff;
}
table.paleBlueRows thead th:first-child {
  border-left: none;
}

table.paleBlueRows tfoot {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  background: #d0e4f5;
  border-top: 3px solid #444444;
}
table.paleBlueRows tfoot td {
  font-size: 14px;
}

.text-box {
  border: 0;
  background-color: transparent;
  width: 90%;
}
.radio-box {
  align-self: center;
}

/*Second Table (Overview of our solution)*/
table.table2 {
  border: 1px solid #000000;
  width: 90%;
  text-align: left;
  border-collapse: collapse;
}
table.table2 td,
table.table2 th {
  border: 1px solid #000000;
  padding: 5px 4px;
}
table.table2 tbody td {
  font-size: 13px;
}
table.table2 thead {
  background: #cfcfcf;
  background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  background: -webkit-linear-gradient(
    top,
    #dbdbdb 0%,
    #d3d3d3 66%,
    #cfcfcf 100%
  );
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  border-bottom: 3px solid #000000;
}
table.table2 thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table.table2 tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
table.table2 tfoot td {
  font-size: 14px;
}

.red {
  color: red;
}

.apmadetailscard {
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 13px !important;
  border: 1px solid #07bdf4;
}

/* Section II – Timeline*/
table.table3 {
  border: 1px solid #000000;
  width: 120%;
  text-align: left;
  border-collapse: collapse;
}
table.table3 td,
table.table3 th {
  border: 1px solid #000000;
  padding: 5px 4px;
}
table.table3 tbody td {
  font-size: 13px;
}
table.table3 thead {
  background: #cfcfcf;
  background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  background: -webkit-linear-gradient(
    top,
    #dbdbdb 0%,
    #d3d3d3 66%,
    #cfcfcf 100%
  );
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  border-bottom: 3px solid #000000;
}
table.table3 thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table.table3 tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
table.table3 tfoot td {
  font-size: 14px;
}

.rfp-table {
  width: 100% !important;
}
table.GeneratedTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #007bff;
  border-style: solid;
  color: #000000;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
}

table.GeneratedTable td,
table.GeneratedTable th {
  border-width: 2px;

  border-color: #007bff;
  border-style: solid;
  padding: 4px 10px;
}

table.GeneratedTable thead {
  background-color: #007bff;
}

.apma-form-logo {
  height: 60px;
}

.hr-border-color {
  border-color: #333333;
}

.apma-profile-logo {
  height: 160px;
}

.layout-apma-logo-padding {
  padding-top: 0px !important;
}

.layout-main {
  min-height: 93vh;
}
.footerImgLogo {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  padding: 10px 15px;
  width: 100%;
}
.logo-back {
  background-color: #112a52;
}

@media (max-width: 480px) {
  .imgCenter {
    text-align: center;
    margin: 8px auto;
  }
  .imgCenter img {
    float: none !important;
    margin-bottom: 0px !important;
  }
}

.border-b-l-r {
  border: 1px solid #ddd;
  border-top: 0px;
  margin-bottom: 1rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.titleBga {
  margin: 0px !important;
  padding: 10px !important;
  background-color: #1c4482;
  color: #ffffff;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.max-width {
  width: 100%;
}
.max-width .display-inline {
  display: inline-block;
}
@media screen and (min-width: 960px) {
  .phoneNumberDialog {
    width: 35vw;
  }
}

.resource-gallery {
  display: inline-block;
  width: 100%;
}
.img-gallery {
  margin-right: 5px;
  width: 200px !important;
  height: 200px !important;
  margin-bottom: 1rem;
}
.file-icon {
  width: 200px;
  height: 200px;
  padding: 2rem 2rem;
  border: 1px solid#ddd;
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
}
.file-icon svg {
  height: 130px;
  width: 130px;
}
.resource-gallery .video-thumb {
  display: inline-block;
  margin: 0px 5px;
  margin-bottom: 1rem;
  vertical-align: top;
}
.resource-gallery .video-thumb .react-thumbnail-generator img {
  margin-right: 5px;
  width: 200px !important;
  height: 200px !important;
}
.video-player {
  background-color: black;
}

/* ===================== DataTable Responsive========== */

.custom-table-responsive .p-datatable-header {
  display: none;
}
/* 
.datatable-responsive-demo
  .p-datatable-responsive-demo
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
} */

/* .table-responsive .p-datatable-wrapper table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
} */
.custom-table-responsive .action-container span {
  padding: 5px;
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 768px) {
  .custom-table-responsive .p-datatable-wrapper table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .custom-table-responsive .action-container span {
    padding: 5px;
  }
}
@media (max-width: 575.98px) {
  .aboutusPage .card h1 {
    font-size: 20px;
  }
  .datatable-filter .table-header {
    display: inline-block;
    justify-content: space-between;
  }
  .compyRegistrList .table-header h2 {
    width: 100%;
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
  .Company-List-model {
    width: 94% !important;
  }
  .Company-List-model .table-header .p-input-icon-left .p-inputtext {
    width: 100%;
  }
  .Company-List-model .table-header h2,
  .datatable-filter .table-header h2 {
    margin-bottom: 0.5rem;
  }
  .Company-List-model .table-header {
    display: inline-block;
    justify-content: space-between;
  }
  .monthly-register-panel .p-dropdown {
    width: 60% !important;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .Company-List-model .table-header .p-input-icon-left .p-inputtext {
    width: 100%;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.img-size {
  width: 100%;
}

.aboutusPage {
  padding: 0px;
}
.centered-text-in-HomePage {
  position: absolute;
  top: 20%;
  left: 15%;
  /* transform: translate(-50%, -50%); */
  border-bottom: 0px !important;
}
.HomePageDesign {
  font-size: 30px !important;
  font-weight: bold !important;
  color: #243746 !important;
  border-bottom: 0px !important;
}
.HomePageDesignParagraph {
  font-weight: 600;
}

.HomePageBanner {
  height: 100%;
  width: 100%;
}

.HomePageBannerText {
  text-align: center;
  padding-top: 15px !important;
  margin-left: 15px;
  margin-right: 15px;
}
.HomePageDesignDownParagraph {
}

.aboutUsPageDesgin {
  padding: 0px !important;
}

@media (min-width: 801px) {
  .HomePageBannerText {
    position: absolute;
    width: 40%;
    top: 20%;
    left: 10%;
    text-align: left;
  }
}
.p-accordion-header-link {
  background-color: #dbecf6 !important;
}

.p-accordion-header-link:hover {
  background-color: #0075c9 !important;
}

.custom-logo-upload input[type="file"] {
  display: none;
}

.custom-logo-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 1rem;
  color: #1c4482;
}

.custom-logo-upload:hover {
  color: #fff;
  background-color: #1c4482;
}

.custom-logo-upload:active {
  color: #fff;
  background-color: #23a6d5;
}
.pi-chevron-down {
  color: #000 !important;
}
.pi-chevron-right {
  color: #000 !important;
}
/* ----- company profile resource file css ---- */
.image-parent svg {
  height: 11.6em;
}

.image-parent {
  position: relative;
  display: inline-block;
}
.image-parent:hover .resource-file-delete-icon {
  display: block;
}

.resource-file-delete-icon {
  padding-top: 7px;
  padding-right: 7px;
  padding-left: 7px;
  padding-bottom: 7px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  color: #fff;
  background-color: red;
}

.company-profile-logo-bg {
  background: #112a52 !important;
  width: 110px;
  height: 110px;
}

.custom-logo-upload input[type="file"] {
  display: none;
}

.custom-logo-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 1rem;
  color: #1c4482;
}

.custom-logo-upload:hover {
  color: #fff;
  background-color: #1c4482;
}

.custom-logo-upload:active {
  color: #fff;
  background-color: #23a6d5;
}

/* .attachment-button {
  border: 0px solid #004b84;
  background: #007bff;
  padding: 3px 21px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  color: #ffffff;
  font-size: 12px;
  font-family: Questrial;
  text-decoration: none;
  vertical-align: middle;
  letter-spacing: 2px;
}

.attachment-button:hover {
  border: 0px solid #1292e1;
  background: rgb(221, 89, 89);
  color: white !important;
  content: "Completed!" !important;
}

.attachment-button:active {
  background: #1292e1;
  color: white;
  text-decoration: none !important;
} */

/* .attachment-button:hover span {
  display: none;
} */

.attachment-button {
  width: 100%; /* set a width so it doesnt change upon hover */
  border: 0px solid #004b84;
  background: #007bff;
  padding: 3px 21px;
  padding-bottom: 4px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  font-family: Questrial;
  text-decoration: none;
  vertical-align: middle;
  letter-spacing: 2px;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.attachment-button:hover span {
  display: none;
}

.attachment-button:hover:before {
  content: "Remove!";
}

.attachment-button:hover {
  background-color: red;
  text-align: center;
}

.attachment-button-padding {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
  margin-top: 0px;
}
